<template>
    <div class="switch-item comment-for " data-type="3" style="display: block;">
    <div class="order-rule"><span>默认时间排序</span></div>
	<ul>
		<li v-for="comment in commentList" :key="comment.id">
			<a class="face">
				<img :src="comment.img" alt="" width="60" height="60">
			</a>
            <div class="info">
                    <div class="title"  >
                        <span class="nickname">{{comment.name}}</span>
                        <span   >
                            <span>：</span>{{comment.Message}}
                        </span>
                    </div>
                    <!-- <div >{{comment.createTime}}</div> -->
               <div style="text-align:right;font-size:15px">{{comment.createTime}}</div>
            </div>
		</li>
	</ul>
      <div class="page-nav">
    <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="hidePageNav"
        :total="totlePage"
        :page-size="pageSize"
        @prev-click="prevPage" @next-click="nextPage"  @current-change="currentPage">
    </el-pagination>
  </div>
  </div>
</template>

<script>
import {DELETE,QUERY,INSERT, UPDATE,QUERYED}  from '@/services/dao.js';
import {  showTimeFormat} from "@/js/common/index.js";
export default {
 data(){
     return{
         commentList:[],
         hidePageNav:true,
         pageNum:1,
         pageSize:12,
         totlePage:0,
     }
 },methods:{
         //初始化
        init(){
             this.searchInfo(this.pageSize,0);
        },
        //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
     async searchInfo(limit,offset){
          this.authorId= Number(this.$route.query.id) ;
        //alert(this.authorId)
          let info=  await  QUERYED("post","",'   TTPretiveMessageUser(where: {beuser : {_eq: '+this.authorId+'}},limit: '+limit+', offset: '+offset+') {  Message    beuser   createTime  id    img   name user   userid  }  TTPretiveMessageUser_aggregate(where: {beuser : {_eq: '+this.authorId+'}}) {    aggregate {      count    }  }');
          this.commentList.splice(0,this.commentList.length);
            for(let i=0;i<info.data.TTPretiveMessageUser.length;i++){
                this.commentList.push({
                   id:info.data.TTPretiveMessageUser[i].id,
                   Message:info.data.TTPretiveMessageUser[i].Message,
                   beuser:info.data.TTPretiveMessageUser[i].beuser,
                   createTime:showTimeFormat(info.data.TTPretiveMessageUser[i].createTime),
                   img:this.api.LoginURL.concat(info.data.TTPretiveMessageUser[i].img),
                   user:info.data.TTPretiveMessageUser[i].user,
                   userid:info.data.TTPretiveMessageUser[i].userid,
                   name:info.data.TTPretiveMessageUser[i].name
                });
            }
            this.totlePage=info.data.TTPretiveMessageUser_aggregate.aggregate.count;
     }
 }
 ,created(){
      this.init();
 }

}
</script>

<style scoped>
.page-nav{
   width: 100%;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
    border: 0;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
}
.comment-for li {
    padding: 15px 0;
    border-bottom: 1px solid #E6E6E6;
    overflow: hidden;
    font-size: 14px;
}
.comment-for li .face {
    /* width: 60px;
    height: 60px; */
    font-size: 0;
    float: left;
    margin-right: 5px;
    position: relative;
    top: -13px;
    /* border: 1px solid red; */
}
.comment-for li .face img {
    border-radius: 50%;
}
.comment-for li .info {
    overflow: hidden;
    padding-right: 70px;
}
.comment-for li .title {
    font-size: 14px;
    line-height: 20px;
    /* border: 1px solid red; */
}
.comment-for .info p {
    line-height: 18px;
    padding-top: 10px;
    font-size: 14px;
    color: #31424e;
}
.comment-for li .nickname {
    color: #31424e;
}
.comment-for li .title a {
    font-size: 12px;
    color: #5e6b73;
}
.comment-for li .title a span {
    font-size: 12px;
    color: #9baab6;
}
.order-rule{
    text-align: right;
}
.order-rule span{
    padding-right:70px
}
</style>